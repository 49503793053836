@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local('Rubik Light '), local('Rubik-Light'),
    url(./fonts/rubik-latin-300.woff2) format('woff2'),
    url(./fonts/rubik-latin-300.woff) format('woff');
}
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: local('Rubik Light italic'), local('Rubik-Lightitalic'),
    url(./fonts/rubik-latin-300italic.woff2) format('woff2'),
    url(./fonts/rubik-latin-300italic.woff) format('woff');
}
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local('Rubik Regular '), local('Rubik-Regular'),
    url(./fonts/rubik-latin-400.woff2) format('woff2'),
    url(./fonts/rubik-latin-400.woff) format('woff');
}
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: local('Rubik Regular italic'), local('Rubik-Regularitalic'),
    url(./fonts/rubik-latin-400italic.woff2) format('woff2'),
    url(./fonts/rubik-latin-400italic.woff) format('woff');
}
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local('Rubik Medium '), local('Rubik-Medium'),
    url(./fonts/rubik-latin-500.woff2) format('woff2'),
    url(./fonts/rubik-latin-500.woff) format('woff');
}
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src: local('Rubik Medium italic'), local('Rubik-Mediumitalic'),
    url(./fonts/rubik-latin-500italic.woff2) format('woff2'),
    url(./fonts/rubik-latin-500italic.woff) format('woff');
}
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: local('Rubik SemiBold '), local('Rubik-SemiBold'),
    url(./fonts/rubik-latin-600.woff2) format('woff2'),
    url(./fonts/rubik-latin-600.woff) format('woff');
}
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: local('Rubik SemiBold italic'), local('Rubik-SemiBolditalic'),
    url(./fonts/rubik-latin-600italic.woff2) format('woff2'),
    url(./fonts/rubik-latin-600italic.woff) format('woff');
}
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local('Rubik Bold '), local('Rubik-Bold'),
    url(./fonts/rubik-latin-700.woff2) format('woff2'),
    url(./fonts/rubik-latin-700.woff) format('woff');
}
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: local('Rubik Bold italic'), local('Rubik-Bolditalic'),
    url(./fonts/rubik-latin-700italic.woff2) format('woff2'),
    url(./fonts/rubik-latin-700italic.woff) format('woff');
}
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: local('Rubik ExtraBold '), local('Rubik-ExtraBold'),
    url(./fonts/rubik-latin-800.woff2) format('woff2'),
    url(./fonts/rubik-latin-800.woff) format('woff');
}
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src: local('Rubik ExtraBold italic'), local('Rubik-ExtraBolditalic'),
    url(./fonts/rubik-latin-800italic.woff2) format('woff2'),
    url(./fonts/rubik-latin-800italic.woff) format('woff');
}
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: local('Rubik Black '), local('Rubik-Black'),
    url(./fonts/rubik-latin-900.woff2) format('woff2'),
    url(./fonts/rubik-latin-900.woff) format('woff');
}
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src: local('Rubik Black italic'), local('Rubik-Blackitalic'),
    url(./fonts/rubik-latin-900italic.woff2) format('woff2'),
    url(./fonts/rubik-latin-900italic.woff) format('woff');
}
