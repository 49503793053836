.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #feeb50;
  color: black;
  max-height: 100px;
}

.enter {
  height: 0px;
}

.enterActive {
  height: 100px;
  transition: height 175ms ease-in;
}

.enterDone {
  height: 100px;
}

.exit {
  height: 100px;
}

.exitActive {
  height: 0px;
  transition: height 175ms ease-in;
}
