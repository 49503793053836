@keyframes bounce {
  0% {
    transform: scale(0.65);
  }

  16% {
    transform: scale(1);
  }

  28% {
    transform: scale(0.85);
  }

  44% {
    transform: scale(1);
  }

  59% {
    transform: scale(0.95);
  }

  73% {
    transform: scale(1);
  }

  88% {
    transform: scale(0.97);
  }

  100% {
    transform: scale(1);
  }
}

.translucentCenterLayout {
  z-index: 100000;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: hidden;
}

.transparentTop {
  position: fixed;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  overflow-y: hidden;
}

.surface {
  display: flex;
  background-color: white;
  flex-direction: column;
  padding: 1rem;
  border-radius: 1rem;
  min-width: 500px;
}

.animated {
  animation: bounce 500ms;
}

.header {
  display: flex;
  color: black;
  font-size: 1.25rem;
  font-weight: bold;
  flex: 1;
  justify-content: space-between;
}

.body {
  display: flex;
  flex: 5;
  justify-content: center;
  overflow-y: scroll;
}

.footer {
  display: flex;
  flex: 1;
  min-height: 1rem;
}
