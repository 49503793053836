.enter {
  position: fixed;
  top: 0;
  height: 0vh;
}

.enterActive {
  position: fixed;
  top: 0;
  height: 250px;
  transition: height 250ms ease-out;
}

.enterDone {
  position: fixed;
  top: 0;
  height: 250px;
}

.exit {
  position: fixed;
  top: 0;
  height: 250px;
}

.exitActive {
  position: fixed;
  top: 0;
  height: 0px;
  transition: height 250ms ease-out;
}
