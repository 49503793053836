.appContainer {
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
}

.bodyContainer {
  display: flex;
  flex-direction: column;
  height: 95vh;
  overflow-y: scroll;
}

.footerContainer {
  display: flex;
  height: 5vh;
}
