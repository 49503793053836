.dropdown {
  z-index: 10000;
  background-color: white;
  box-shadow: 0px 5px 10px grey;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  flex: 1;
  justify-content: center;
  overflow-y: scroll;
}
