.confirmationBody {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin: 1rem;
}

.buttons {
  display: flex;
  justify-content: center;
  column-gap: 1rem;
}
